<template>
<div>
  <el-tabs v-model="system.activeid" 
  type="card" 
  closable 
  @tab-remove="removeTab">
    <el-tab-pane
      :key="item.id"
      v-for="item in system.mdilist"
      :label="item.title"
      :name="item.id"
      style="padding:0 20px;"
    >
     <component :is="item.id" :id="item.id" :menu="item" :system="system" :content="content" ></component>
    </el-tab-pane>
  </el-tabs>
</div>
</template>
<script>
  import ax from "@/js/ax.js"
  
  export default {
    extends:ax.control(),
    data() {
      return {
        editableTabsValue:undefined,
        editableTabsValue:"",
        content:{
          pagestatus:"view",
          row:{},
        }
      }
    },
    created(){
      this.system.mdilist=[];
    },
    mounted(){
      // console.log(this.system.activeid);
      // this.system.mdilist=[{id:1}];
      // console.log(this.system.mdilist);
    },
    methods: {
      test(){
      },
      removeTab(targetName) {
        let tabs = this.system.mdilist;
        let activeName = this.system.activeid;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.id === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.id;
              }
            }
          });
        }
        this.system.activeid = activeName;
        this.system.mdilist = tabs.filter(tab => tab.id !== targetName);
      }
    }
  }
</script>