import Vue 			from 'vue'
import axios 		from 'axios'

Vue.prototype.$http = axios

axios.defaults.withCredentials=true;

axios.interceptors.request.use(
    config => {
      if (localStorage.getItem('Authorization')) { 
        config.headers.Authorization = localStorage.getItem('Authorization');
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

 // 拦截响应response，并做一些错误处理
 axios.interceptors.response.use((response) => {
    return response;
 },(error) =>{
    if(error.response.data.status == 401){
        localStorage.removeItem('Authorization');
        window.location='/login';
    }
    return Promise.reject(error);
});