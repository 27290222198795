import Vue from 'vue'
require('@/js/config.js');
// 公共基础方法。通过传voucher对象来完成单据的通用方法
// voucher.model 模块 eg: system 
// voucher.menu  菜单 eg: sysUser
// voucher.this this
// 其实这些可以通过后台数据库存的值来完成前端方法调用。
class Base{
    constructor(voucher){
        this.voucher = voucher; 
        this.preurl = "/zhenwei";
        this.preurl = "";
    }
    // 公共请求    multipart/form-data-- 文件流上传
    // multipart/form-data 取 params  json 取 data
    request(params){       
        const vm = new Vue();
        return new Promise((resolve,reject)=>{
             vm.$http({
                method: params.type||'get',
                url: params.url||'',
                dataType: params.dataType||'json',
                data: params.data||null,
                params: params.data||null,
                // setTimeout:600,
                headers: params.headers||{'Content-type': 'application/json'},    
                responseType:params.responseType ||'json'
            }).then(function(result){      
                typeof resolve === 'function' && resolve(result.data);
            }).catch(function(error){    
                typeof reject === 'function' && reject(error.response.data)
            })
        })
    }
    test(){
        console.log("base.test");
        return "1"
    }
    get(url,data){
        return this.request({
            type:"get",
            url:this.preurl+url,
            data:data,
            dataType:'json'
        })
    }
    post(url,data){
        return this.request({
            type:"post",
            url:this.preurl+url,
            data:data,
            dataType:'json'
        })
    }
    postfile(url,data){
        return this.request({
            type:"post",
            url:this.preurl+url,
            data:data,
            dataType:'blob',
            responseType:"blob",
            // headers:{"Content-type":"application/vnd.ms-excel"}
        })

    }
    /**
     *  自定义方法
     * @param {get,put,post 等} method 
     * @param {java 方法 或自定义路径} javaMethod 
     * @param {参数} data 
     * @param {是否自定义} isDefine 
     */
    reqRule(javaMethod, method, data ){
        return this.request({
            type: method,
            url:  this.voucher.model + '/' + this.voucher.menu + '/' + javaMethod,
            data: data,
            dataType: 'json'
        })
    }
    reqFree(javaMethod, method, data ){
        return this.request({
            type: method,
            url:  javaMethod ,
            data: data,
            dataType: 'json'
        })
    }
    reqExcel(javaMethod, method, data ){
        return this.request({
            type: method,
            url:  javaMethod ,
            data: data,
            dataType: 'json',
            responseType: 'blob'
        })
    }
    // 单元 单条记录 查找
    queryById(id){
        return  this.request({
            type: 'get',
            url: this.voucher.model + '/' + this.voucher.menu +'/' + id,
        })
    }
    // 查找所有
    queryAll(){
        return  this.request({
            type: 'get',
            url: this.voucher.model + '/' + this.voucher.menu +'/queryAll',
        })
    }
    // 单元 修改或新增 isEdit = true 修改（put） else 新增(save)
    save(isEdit, data){
        return this.request({
            type: isEdit ? 'put':'post',
            url: this.voucher.model + '/' + this.voucher.menu +'/',
            data: data,
        })
    }
    // 单元 删除 待测试
    delete(id){
        return this.request({
            type: 'delete',
            url: this.voucher.model + '/' + this.voucher.menu +'/',
            data:{'id': id},
        })
    }
    // 单元通用方法
    setStorage(name,data){
		let dataType = typeof data
		// 这是JSON类型
		if(dataType === 'object'){
			window.localStorage.setItem(name,JSON.stringify(data));
		}
		// 基础类型
		else if( ['number','string','boolean'].indexOf(dataType) >= 0){
			window.localStorage.setItem(name,data);
		}
		else{
			alert('该类型不能用于本地存储')
		}
	}

	// 提取存储的内容
	getStorage(name){
		return window.localStorage.getItem(name);
    }
    // 数据字黄中拿值
     select(code){
        if(this.isEmpty(code)) return '';
         return this.reqFree('system/sysKindd/queryByKindCode/kindCode/'+ code);
    }
    isEmpty(obj){
        if(typeof obj == "undefined" || obj == null || obj == ""){
            return true;
        }else{
            return false;
        }
    }
    copy(obj){
        return JSON.parse(JSON.stringify(obj));
    }
    uuid () {
        let s = [];
        let hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = "-";
        let uuid = s.join("");
        return uuid;
    }
     getNowFormatDate() {
        var date = new Date();
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        var currentdate = year + seperator1 + month + seperator1 + strDate;
        return currentdate;
    }
}
export default Base;