import Base from "@/js/base.js";
import Vue from "vue";
import Print from '@/js/print'
Vue.use(Print)
const _base = new Base({});
// 程序切换
import localdata from "@/js/localdata-yaoan";
// import localdata from "@/js/localdata-fst";
// import localdata from "@/js/localdata-fdk";
// import localdata from "@/js/localdata-saiz";
// import localdata from "@/js/localdata-xigedianzi"
// Vue.prototype.$http.defaults.baseURL = 'http://127.0.0.1:8881' //服务器cls、
let _model = {
    type: "dev",
};
var ax = (function(_base, _model, localdata, Vue) {
    let _ax = {
        model: _model,
        base: _base,
        localdata: localdata,
        control: function() {
            let _control = {
                props: ["system", "item", "menu"],
                data() {
                    return {
                        rs: [],
                        params: {
                            type: "ax",
                            remark: "",
                            details: {
                                codes: "",
                                codee: "",
                                name: ""
                            },
                            page: {
                                size: 500,
                                current: 1,
                                total: 0
                            }
                        },
                        vouchertype: "",
                        p: {
                            remark: "",
                        },
                        rpt: {
                            datee: "",
                            dates: "",
                        },
                        dataset: {
                            accounts: [],
                            rs: [],
                            type: "",
                        }
                    }
                },
                mounted() {},
                methods: {
                    getaccounts() {
                        let _this = this;
                        _base.post("/syc/getDetail", { type: _this.dataset.type, remark: _this.p.remark })
                            .then((data) => {
                                _this.dataset.accounts = data.data;
                            })
                            .catch((data) => {});
                    },
                    //table行样式
                    rowStyle(row, rowIndex) {
                        return {
                            padding: '2px 0 2px 0',
                            fontSize: '13px',
                            height: '25px',
                        }
                    },
                }
            }
            return _control;
        },
        control1: function() {
            let _control = {
                props: ["menu", "content", "system"],
                data() {
                    return {
                        "DataSet": {},
                        "meta": {
                            "qrow": 2,
                            "h": 0, // 可变区域高。
                        },
                        "settings": "",
                        "model": "",
                        "vouchertype": "list",
                        p: {}, //list查询
                        p1: {
                            page: {
                                size: 20,
                                current: 1,
                                total: 0
                            }
                        }, // list page查询 
                        rs: [], //list 结果 
                        mastertable:"",
                        params: {
                            pagestatus: "",
                            DataSet: ""
                        },   // detail  params
                        menubarstatus:{
                            add:true,
                            update:true,
                            cancel:false,
                            save:false
                        },
                        dataquery: {
                            
                        }, // sysdetailload
                        currenttable:"",
                    }
                },
                created() {
                    if(this.vouchertype == "detail"){
                        this.DataSet = ax.dataset(this.datainit);
                        this.DataSet.init();
                    }
                },
                mounted() {
                    this.geth();
                    if(this.vouchertype=="list"){
                        this.syslistquery();
                    }else if(this.vouchertype == "detail"){
                        if (this.content.pagestatus == 'add') {} else if (this.content.pagestatus == 'view') {
                            this.sysdetailquerybyguid();
                        }   
                    }
                },
                methods: {
                    geth() {
                        this.meta.h = document.body.clientHeight - this.meta.qrow * 28 - 41 - 60 - 48 - 20
                    },
                    syslistquery() {
                        let _this = this;
                        let pp = {
                            details: this.p ,
                            page: this.p1.page
                        }
                        _base.post("/"+this.model+"/"+this.settings+"/syslistquery", pp).then(data => {
                            _this.rs = data.data.records;
                            _this.p1.page.total = data.data.total;
                        })
                    },
                    pagechange({ currentPage, pageSize }) {
                        this.p1.page.current = currentPage;
                        this.p1.page.size = pageSize;
                        this.syslistquery();
                    },
                    syslistdeleteone(row) {
                        this.$XModal.confirm('您确定要删除吗？').then(type => {
                            if (type == 'confirm') {
                                let _this = this;
                                let pp = {
                                    "guid": row.guid
                                }
                                _base.post("/"+this.model+"/"+this.settings+"/syslistdeleteone", pp).then(data => {
                                    _this.$XModal.message({ message: "删除成功!", status: 'success' });
                                    _this.syslistquery();
                                }).catch(data => {
                                    _this.$XModal.message({ message: data.data, status: 'error' })
                                })
                            }
                        })
                    },
                    syslistqueryone(row) {
                        Vue.component(this.menu.id + "-detail", () => import('@/' + this.menu.componentdetail))
                        let item = {
                            "id": this.menu.id + "-detail",
                            "pageFlag": this.menu.pageFlag,
                            "title": this.menu.title + "-明细",
                            "name": this.menu.name + "-detial",
                            "icon": this.menu.icon
                        }
                        let tables = {
                            id: item.id,
                            title: item.title,
                            name: item.name,
                            content: item.name,
                            icon: item.icon
                        }
                        this.$set(this.content, "row", row)
                        console.log(tables)
                        if (!this.$ax.arr.indexof(this.system.mdilist, tables)) {
                            this.system.mdilist.push(tables)
                            this.system.activeid = item.id
                            this.content.pagestatus = "view"
                        } else {
                            // this.content.pagestatus = "view"
                            this.system.activeid = item.id
                        }
                    },
                    syslistadd() {
                        Vue.component(this.menu.id + "-detail", () => import('@/' + this.menu.componentdetail))
                        let item = {
                            "id": this.menu.id + "-detail",
                            "pageFlag": this.menu.pageFlag,
                            "title": this.menu.title + "-明细",
                            "name": this.menu.name + "-detial",
                            "icon": this.menu.icon
                        }
                        let tables = {
                            id: item.id,
                            title: item.title,
                            name: item.name,
                            content: item.name,
                            icon: item.icon
                        }
                        if (!this.$ax.arr.indexof(this.system.mdilist, tables)) {
                           // alert("aa");
                            this.system.mdilist.push(tables)
                            this.system.activeid = item.id
                            this.content.pagestatus = "add"

                            this.menubarstatus.add=true;
                            this.menubarstatus.update=true;
                            this.menubarstatus.save=false;
                            this.menubarstatus.cancel=false;
                        } else {
                            this.system.activeid = item.id
                        }
                      
                    },
                    rowStyle(row, rowIndex) {
                        return {
                            padding: '2px 0 2px 0',
                            fontSize: '13px',
                            height: '25px',
                        }
                    },
                    getstatus() {
                        if ("view" == this.content.pagestatus) {
                            return "状态:就绪"
                        } else if ("add" == this.content.pagestatus) {
                            return "状态:新增"
                        } else if ("update" == this.content.pagestatus) {
                            return "状态:修改"
                        }
                    },
                    sysdetailadd() {
                        if (this.content.pagestatus != 'view') {
                            this.$XModal.message({ message: "当前状态不允许新增", status: 'success' })
                            return;
                        }
                        // alert("a");
                        this.menubarstatus.add=true;
                        this.menubarstatus.update=true;
                        this.menubarstatus.save=false;
                        this.menubarstatus.cancel=false;

                        this.DataSet = ax.dataset(this.datainit);
                        this.DataSet.init();
                        this.content.pagestatus = 'add'

                    },
                    sysdetailupdate() {
                        if (this.content.pagestatus != 'view') {
                            this.$XModal.message({ message: "当前状态不允许修改", status: 'success' })
                            return;
                        }
                        this.content.pagestatus = 'update';
                        let _this = this;
                        _this.menubarstatus.add=true;
                        _this.menubarstatus.update=true;
                        _this.menubarstatus.save=false;
                        _this.menubarstatus.cancel=false;
                    },
                    sysdetailload() {
                        this.DataSet = ax.dataset(this.datainit);
                        this.DataSet.init();
                        let as = this.dataquery.a;
                        
                        for (let a of as) {
                            this.DataSet.tables[this.mastertable].viewrow(a);
                        }
                        this.DataSet.tables[this.mastertable].currentrowindex = 0;
                        this.DataSet.tables[this.mastertable].valuechange = function(row, column, value, jsexps) {
                            if (row.rowstate == "none") {
                                row.rowstate = "modify"
                            }
                        }
                        this.after_sysdetailload();

                    },
                    after_sysdetailload(){
                    },
                    sysdetailquerybyguid() {
                        let row = this.content.row;
                        let _this = this;
                        this.content.pagestatus = 'view';
                        _base.post("/"+this.model+"/"+this.settings+"/sysquerybyguid", row).then(data => {
                            _this.dataquery = data.data;
                            _this.content.pagestatus = 'view';
                            _this.sysdetailload();
                            _this.menubarstatus.add=false;
                            _this.menubarstatus.update=false;
                            _this.menubarstatus.save=true;
                            _this.menubarstatus.cancel=true;
                        })
                    },
                    sysdetailgetsaveparams(){
                        this.$set(this.params, "as", this.DataSet.todict()[this.mastertable].rows)
                    },
                    sysdetailsave() {
                        if (this.content.pagestatus == 'view') {
                            this.$XModal.message({ message: "当前状态不允许保存", status: 'success' })
                            return;
                        }
                        let _this = this;
                        this.params.pagestatus = this.content.pagestatus;
                        this.sysdetailgetsaveparams();
                       
                        _base.post("/"+this.model+"/"+this.settings+"/sysdetailsave", _this.params).then(data => {
                                _this.$set(_this.content.row, "guid", data.data.as[0].guid);
                                _this.sysdetailquerybyguid()
                                _this.$XModal.message({ message: "保存成功！", status: 'success' })
                                _this.menubarstatus.add=false;
                                _this.menubarstatus.update=false;
                                _this.menubarstatus.save=true;
                                _this.menubarstatus.cancel=true;
                            })
                            .catch(data => {
                                _this.$XModal.message({ message: data.data, status: 'error' })
                            })
        
                    },
                    sysdetaildtadd(tablename){
                        if (this.content.pagestatus == 'view') {
                            this.$XModal.message({ message: "当前状态不允许增加", status: 'success' })
                            return;
                        }
                        let _row = this.DataSet.tables[tablename].addrow(this.DataSet.tables[tablename].rowmodel);
                        this.$refs[tablename].setCurrentRow(_row)
                        this.menubarstatus.add=true;
                        this.menubarstatus.update=true;
                        this.menubarstatus.save=false;
                        this.menubarstatus.cancel=false;
                    },
                    sysdetaildtdel(tablename) {
                        if (this.content.pagestatus == 'view') {
                            this.$XModal.message({ message: "当前状态不允许删除", status: 'success' })
                            return;
                        }
                        this.DataSet.tables[tablename].deleterow(this.DataSet.tables[tablename].currentrow);
                        this.$refs[tablename].setCurrentRow(this.DataSet.tables[tablename].currentrow)
                    },
                    sysdetaildtinsert(tablename){
                        if (this.content.pagestatus == 'view') {
                            this.$XModal.message({ message: "当前状态不允许插入", status: 'success' })
                            return;
                        }
                        if(this.DataSet.tables[tablename].rows.length ==0) return;
                        if(this.DataSet.tables[tablename].currentrow ==undefined ||this.DataSet.tables[tablename].currentrow ==null  ) return;
                        // 取当前guid
                        let currentguid = this.DataSet.tables[tablename].currentrow['guid']
                        let seq = Number(this.DataSet.tables[tablename].currentrow['seq'])
                        for(let i= this.DataSet.tables[tablename].rows.length-1;i>=0;i--){
                            let rowseq  = Number(this.DataSet.tables[tablename].rows[i]['seq'])
                            if (rowseq != seq ){
                                this.DataSet.tables[tablename].rows[i]['seq'] = rowseq+1;
                            }
                            else if(rowseq == seq ){
                                let _row = this.DataSet.tables[tablename].newrow(this.DataSet.tables[tablename].rowmodel);
                                _row['seq']= rowseq
                                this.DataSet.tables[tablename].rows.splice(i,0,_row);
                                this.$refs[tablename].setCurrentRow(_row)
                                break;
                            }
                        }
                    },
                    // sycquotation(row) {
                    //     this.menu.componentdetail="views/quote/test1.vue";
                    //     Vue.component(this.menu.id + "-detail", () => import('@/' + this.menu.componentdetail))
                    //     let item = {
                    //         "id": this.menu.id + "-detail",
                    //         "pageFlag": this.menu.pageFlag,
                    //         "title":  "报价单",
                    //         "name": this.menu.name + "-detial",
                    //         "icon": this.menu.icon
                    //     }
                    //     let tables = {
                    //         id: item.id,
                    //         title: item.title,
                    //         name: item.name,
                    //         content: item.name,
                    //         icon: item.icon
                    //     }
                    //     this.$set(this.content, "row", row)
                       
                    //     if (!this.$ax.arr.indexof(this.system.mdilist, tables)) {
                    //        this.system.mdilist.push(tables)
                    //         this.system.activeid = item.id
                    //         this.content.pagestatus = "view"
                    //     } else {
                    //         this.system.activeid = item.id
                    //     }
                    // }
                }
            };
            return _control;
        },
        dataset: function(data) {
            function loadRow(data, valuechange) {
                let _row = {
                    __values: {},
                    __inited: false,
                    __valuechange: valuechange,
                    __state: "none",
                    __columns: [],
                    //__index: 0,
                    setvaluechange: function(fu) {
                        this.__valuechange = fu;
                    },
                    set rowstate(s) {
                        this.__state = s;
                    },
                    get rowstate() {
                        return this.__state;
                    },
                    get columns() {
                        return this.__columns;
                    },
                    delete: function() {
                        if (this.rowstate == "add") {
                            this.rowstate = "cancel";
                        } else {
                            // 当前行索引为行级。row明细里是拿不到的。
                            this.rowstate = "delete";
                        }
                    },
                    accept: function() {
                        this.rowstate = "none";
                    },
                    todict: function() {
                        let __dict = {
                            rowstate: this.rowstate
                        }
                        for (let __c of this.columns) {
                            __dict[__c] = this[__c]
                        }
                        return __dict;
                    }
                }
                if (data.hasOwnProperty("guid")) {
                    if (data["guid"] == "") {
                        data["guid"] = _base.uuid()
                    }
                }
                //   if (data.hasOwnProperty("orgguid")) {
                //     if (data["orgguid"] == "") {
                //       data["orgguid"] = _base.storage.getitem("orgguid")
                //     }
                //   }
                for (let filed in data) {
                    _row.columns.push(filed)
                }
                for (let filed in data) {
                    if (filed == 'rowstate') continue;

                    Object.defineProperty(_row, filed, {
                        set: function(value) {
                            if (this.__values[filed] !== value) {
                                if (this.rowstate == "none") {
                                    this.rowstate = "modify"
                                }
                            }
                            this.__values[filed] = value
                            if (_row.__valuechange != undefined && _row.__inited == true) {
                                _row.__valuechange(filed, value)
                            }
                        },
                        get: function() {
                            return this.__values[filed]
                        }
                    })
                }

                for (let key in data) {
                    _row[key] = data[key]
                }
                _row.__inited = true;
                return _row;
            }

            function loadTable(dist, _dataset) {
                // 解json
                let tbname = dist['keyname']
                let keyname = dist['keyname']
                let ptable = dist['ptable']
                let pkey = dist['pkey']
                let relation = dist['relation']
                let rows = dist['rows']
                let jsexps = dist["jsexps"]
                let hasseq = dist["hasseq"]

                let _table = {
                    tablename: tbname, //表名
                    keyname: keyname, // 表key
                    ptable: ptable, // 父表
                    pkey: pkey, // 父key
                    relation: relation, // 关联key
                    hasseq: hasseq,
                    __jsexps: jsexps,

                    __rows: [],
                    __currentrowindex: 0,
                    __oldcurentrow: undefined,
                    __currentrow: undefined,
                    __maxindex: 0,
                    __rowmodel: undefined,
                    valuechange: function(row, column, value, jsexps) {},
                    get maxindex() {
                        return this.rowsview.length - 1;
                    },
                    get jsexps() {
                        return this.__jsexps;
                    },
                    get rows() {
                        return this.__rows;
                    },
                    get rowsview() {
                        let __rs = [];
                        let rowcount = 1;
                        let ptable = dist['ptable']
                        let pkey = dist['pkey']
                        let relation = dist['relation']
                        for (let r of this.__rows) {
                            if (r.rowstate != "delete" && r.rowstate != "cancel") {
                                if (hasseq) {
                                    if (ptable.length != 0) {
                                        if (tbname == 'ax_vou_offer_c') {
                                            if (_dataset.tables[ptable].currentrow == null) continue;
                                        }
                                        if (_dataset.tables[ptable].currentrow == null) continue;
                                        if (r[relation] == _dataset.tables[ptable].currentrow[pkey]) {
                                            if (r.rowstate == 'none') {
                                                if (rowcount != r["seq"]) {
                                                    r["seq"] = rowcount;
                                                    r.rowstate = "modify";
                                                }
                                            } else if (r.rowstate == 'modify') {
                                                if (rowcount != r["seq"]) {
                                                    r["seq"] = rowcount;
                                                }
                                            } else if (r.rowstate == 'add') {
                                                if (rowcount != r["seq"]) {
                                                    r["seq"] = rowcount;
                                                }
                                            }
                                            rowcount++;
                                            __rs.push(r);
                                        }

                                    } else {
                                        if (r.rowstate == 'none') {
                                            if (rowcount != r["seq"]) {
                                                r["seq"] = rowcount;
                                                r.rowstate = "modify";
                                            }
                                        } else if (r.rowstate == 'modify') {
                                            if (rowcount != r["seq"]) {
                                                r["seq"] = rowcount;
                                            }
                                        } else if (r.rowstate == 'add') {
                                            if (rowcount != r["seq"]) {
                                                r["seq"] = rowcount;
                                            }
                                        }
                                        rowcount++;
                                        __rs.push(r);
                                    }
                                }
                            }
                        }
                        return __rs;
                    },
                    get currentrowindex() {
                        return this.__currentrowindex;
                    },
                    set currentrowindex(index) {
                        this.__currentrowindex = index;
                    },
                    get currentrow() {
                        let __cr = undefined;
                        if (this.rowsview.length == 0) {
                            return null;
                        }
                        try {
                            // 通过行的seq去赋值，取总表的数据较好。
                            __cr = this.rowsview[this.currentrowindex]

                            // if (__cr != undefined)
                            //     return __cr
                            // if (__cr != undefined)
                            //     this.__oldcurentrow = __cr;
                            // if (__cr == undefined && this.__oldcurentrow != undefined)
                            //     __cr = this.__oldcurentrow;
                            // if (__cr == undefined) {
                            //     __cr = this.rowsview[0];
                            //     // __cr = this.rowsview[0];
                            // }
                        } catch (error) {}
                        return __cr;
                    },
                    set currentrow(row) {
                        this.__currentrow = row
                    },
                    set rowmodel(val) {
                        if (this.__rowmodel == null) {
                            this.__rowmodel = val
                        }
                    },
                    get rowmodel() {
                        return this.__rowmodel;
                    },
                    setindex: function() {

                    },
                    loadrow: function(data) {
                        let __row = loadRow(data, undefined);
                        __row.__jsexps = this.jsexps
                        _table.__rows.push(__row);

                        if (data.rowstate == 'none')
                            __row.rowstate = "none";
                        else {
                            __row.rowstate = "modify";
                        }

                        __row.setvaluechange(function(column, value) {
                            _table.valuechange(__row, column, value, __row.__jsexps);
                        });
                        return __row;
                    },
                    deleterow: function(row) {
                        if (this.currentrowindex >= 0) {}
                        if (this.currentrowindex < 1) {
                            this.currentrowindex = 0
                        } else {
                            if (this.currentrowindex == this.rowsview.length - 1) {
                                this.currentrowindex = this.currentrowindex - 1
                            }
                        }
                        if (row.rowstate == 'add') {
                            row.rowstate = "cancel"
                        } else {
                            row.rowstate = "delete"
                        }
                    },
                    newrow:function(data){
                        let ptable = dist['ptable']
                        let pkey = dist['pkey']
                        let relation = dist['relation']
                        if (ptable.length > 0) {
                            if (_dataset.tables[ptable].currentrow == null) {
                                throw new Error('关联列为空，不能增加');
                            }
                            if (_dataset.tables[ptable].currentrow[pkey].length == 0) {
                                throw new Error('关联列为空，不能增加');
                            } else {
                                data[relation] = _dataset.tables[ptable].currentrow[pkey];
                            }
                        }

                     
                        data["guid"] = _base.uuid();
                  
                        let __row = loadRow(data, undefined);
                        __row.__jsexps = this.jsexps
                       

                        if (data.rowstate == 'none')
                            __row.rowstate = "none";
                        else {
                            __row.rowstate = "modify";
                        }

                        __row.setvaluechange(function(column, value) {
                            _table.valuechange(__row, column, value, __row.__jsexps);
                        });
                    
                        __row.rowstate = "add";

                        return __row;
                    },
                    addrow: function(data) {
                        let ptable = dist['ptable']
                        let pkey = dist['pkey']
                        let relation = dist['relation']
                        if (ptable.length > 0) {
                            if (_dataset.tables[ptable].currentrow == null) {
                                throw new Error('关联列为空，不能增加');
                            }
                            if (_dataset.tables[ptable].currentrow[pkey].length == 0) {
                                throw new Error('关联列为空，不能增加');
                            } else {
                                data[relation] = _dataset.tables[ptable].currentrow[pkey];
                            }
                        }

                        data["seq"] = this.maxindex + 1;
                        data["rowindex"] = this.maxindex; // 无效
                        data["guid"] = _base.uuid();
                        this.currentrowindex = this.maxindex + 1;
                        let __row = this.loadrow(data);
                        __row.rowstate = "add";

                        return __row;
                    },
                    viewrow: function(data) {
                        if (this.ptable == "") {
                            this.__rows.splice(0, this.__rows.length)
                        }
                        if (data["seq"] == '') {
                            data["seq"] = this.maxindex + 1;
                        }
                        data["rowindex"] = this.maxindex

                        // 只要增加就给个guid
                        if (data["guid"] == "") {
                            data["guid"] = _base.uuid();
                        }
                        this.currentrowindex = this.maxindex;
                        let __row = this.loadrow(data);
                        __row.rowstate = "none";

                        return __row;
                    },
                    accept: function() {
                        for (let __r of this.rows) {
                            __r.accept();
                        }
                    },
                    todict: function() {
                        let __dict = {
                            tablename: this.tablename,
                            keyname: this.keyname,
                            rows: []
                        };
                        // _table

                        for (let __r of this.rows) {
                            if (this.ptable.length != 0) {
                                let relationvalues = __r[this.relation]
                                let prowrowstate = ''
                                for (let prow of _dataset.tables[this.ptable].rows) {
                                    if (prow[this.pkey] == relationvalues) {
                                        prowrowstate = prow.rowstate
                                        break
                                    }
                                }

                                if (prowrowstate == "cancel") {
                                    continue;
                                } else if (prowrowstate == "delete") {
                                    __r.rowstate = 'delete'
                                }
                            }
                            if (__r.rowstate == "cancel")
                                continue;
                            __dict.rows.push(__r.todict());
                        }
                        return __dict;
                    }
                    //end todict
                }
                let i = 0;
                for (let r of rows) {
                    if (i == 0) {
                        _table.rowmodel = r;
                    }
                    if (hasseq) {
                        if (r["seq"] != ((i + 1) + "")) {
                            r["seq"] = i + 1;
                            r["rowstate"] = "modify";
                        }
                    } else {
                        r["seq"] = i + 1;
                        r["rowstate"] = "none"
                    }
                    r["rowindex"] = i
                    _table.loadrow(r)
                    i++;
                }
                return _table;
            };

            function loadDataSet(data) {
                let _dataset = {
                    __tables: {},
                    get tables() {
                        return this.__tables;
                    },

                    loadtable: function(tbname, keyname, rows) {
                        let __table = loadTable(tbname, keyname, rows);
                        this.__tables.push(__table);
                        return __table;
                    },
                    accept: function() {
                        for (let __tn in this.tables) {
                            this.tables[__tn].accept();
                        }
                    },
                    init: function() {
                        // clear all
                        for (let _ts in this.tables) {
                            
                            this.tables[_ts].currentrowindex = -1;
                            let ts = this.tables[_ts];
                            ts.rows.splice(0, ts.rows.length)
                            if (this.tables[_ts].ptable == "") {
                                this.tables[_ts].addrow(this.tables[_ts].rowmodel)
                                this.tables[_ts].currentrowindex = 0;
                            }
                        }
                    },
                    // 最后pguid塞值,用于tostr()
                    afterinit: function() {
                        let __guid = "";
                        for (let _ts in this.tables) {
                            if (this.tables[_ts].ptable == "") {
                                __guid = this.tables[_ts].rows[0]["guid"]
                            } else {
                                let _relation = this.tables[_ts].relation;
                                for (let i = 0; i < this.tables[_ts].rows.length; i++) {
                                    if (this.tables[_ts].rows[i][_relation] == "") {
                                        this.tables[_ts].rows[i][_relation] = __guid;
                                    }
                                }
                            }
                        }
                    },
                    loadrowstate: function(rowstate) {
                        for (let _ts in this.tables) {
                            if (this.tables[_ts].ptable == "") {
                                this.tables[_ts].rows[0]["rowstate"] = rowstate
                            } else {
                                for (let i = 0; i < this.tables[_ts].rows.length; i++) {
                                    if (this.tables[_ts].rows[i]["rowstate"] == "delete") {
                                        // 删除状态下的不用
                                        continue;
                                    }
                                    this.tables[_ts].rows[i]["rowstate"] = rowstate
                                }
                            }
                        }
                    },
                    todict: function() {
                        //this.afterinit();

                        let __obj = {}
                        for (let __tn in this.tables) {
                            __obj[__tn] = this.tables[__tn].todict();
                        }
                        return __obj;
                    },
                    tostr: function() {
                        return JSON.stringify(this.todict());
                        // return this.todict();
                    },
                }
                for (let tbname in data) {
                    let __dict = data[tbname];
                    let pvalue = "";

                    if (data[tbname]['ptable'] != '') {
                        // pvalue = this.tables[data[tbname]["ptable"]].currentrow[data[tbname]["ptable"]];
                    }
                    __dict['pvalue'] = pvalue;

                    let __table = loadTable(__dict, _dataset);
                    _dataset.__tables[tbname] = __table;
                }
                return _dataset;
            };
            return loadDataSet(data);
        },

        "date": {
            getnow: function(nowDate) {
                var char = "-";
                if (nowDate == null) {
                    nowDate = new Date();
                }
                var day = nowDate.getDate();
                var month = nowDate.getMonth() + 1; //注意月份需要+1
                var year = nowDate.getFullYear();
                //补全0，并拼接
                return year + char + _ax.com.completeDate(month) + char + _ax.com.completeDate(day);
            },
          

        },

        com: {
            completeDate: function(value) {
                return value < 10 ? "0" + value : value;
            }
        },
        arr: {
            indexof: function(_arr, obj, key) {
                if (key == null) {
                    key = 'id'
                }
                let flag = false;
                for (let item of _arr) {
                    if (item[key] == obj[key]) {
                        return true;
                    }
                }
                return false;
            }
        }

    };
    return _ax;
})(_base, _model, localdata, Vue)
export default ax