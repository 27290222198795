<template>
    <el-container :class="isCollapse?'hideMenu':'openMenu'">
        <el-aside>
            <div style="height: 60px;">test</div>
            <SideBar ref="sidebar" class="sidebar-container" :system="system" />
        </el-aside>
        <el-container>
            <el-header style="text-align:right; font-size:16px;">
                <i class="menu_btn" :class="isCollapse?'el-icon-s-unfold':'el-icon-s-fold'" @click="closeSide()"></i>
                <el-dropdown>
                    <a @click="loginOut">登出</a>
                    <i class="el-icon-setting" style="margin-right:5px;font-size:16px;"></i>
                    <span style="font-size:16px;margin-right:10px;">用户名{{userName}}</span>
                    <el-dropdown-menu slot="dropdown" size="medium">
                        <!-- <p @click="changePassword">
              <el-dropdown-item>修改密码</el-dropdown-item>
            </p> -->
                        <!-- <el-dropdown-item>消息</el-dropdown-item> -->
                        <p @click="loginOut">
                            <el-dropdown-item style="width: 150px;margin-top: 5px;overflow: hidden;height: 32px;box-shadow: 0 2px 10px 2px #92b6dd;">登出</el-dropdown-item>
                        </p>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-header>
            <el-main>
                <TabList :system="system" />
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
    import Vue from "vue";
    import SideBar from "./sidebar";
    import TabList from "./sidebar/tabList";
    import ax from "@/js/ax.js";

    export default {
        data() {
            return {
                isCollapse: false,
                userName: localStorage.getItem("userFullName"),
                system: null,
            }
        },
        components: {
            SideBar,
            TabList
        },
        methods: {
            loginOut() {
                localStorage.setItem("Authorization", "");
                localStorage.setItem("userName", "");
                localStorage.setItem("userFullName", "");
                // this.$router.push("/login");
                this.$router.go(0)
            },
            changePassword() {},
            closeSide() {
                this.isCollapse = !this.isCollapse;
                this.$refs.sidebar.isCollapse = this.isCollapse

            },
            test() {},
            createComponent(routerList) {
                for (let i = 0; i < routerList.length; i++) {
                    if (routerList[i].hasOwnProperty('children')) {
                        this.createComponent(routerList[i].children)
                    } else {
                        if (routerList[i].pageFlag) {
                            Vue.component(routerList[i].id, () => import('@/' + routerList[i].component))
                        }
                    }
                }
            }
        },

        created() {
            this.system = this.$ax.localdata.system;
            let _this = this;
            _this.system = _this.$ax.localdata.system;
                //    _this.createComponent(_this.system.menu)
                    // _this.$forceUpdate();
            
            ax.base
                .post("chanjet/axSysPartneruser/getmenu", "")
                .then(function(rs) {
                    if (rs.data != '1') {
                        _this.system = _this.$ax.localdata.system;
                    } else {
                        _this.system.menu = [
                            {
                                pageFlag: true,
                                id: "ax_z_excel_ruku",
                                title: "入库查询",
                                name: "ax_z_excel_ruku",
                                icon: "el-icon-document",
                                component: "views/excel/AxZExcelRukuList1.vue",
                                componentdetail: "",
                                visible: true
                            }
                        ]
                    }
                    _this.system = _this.$ax.localdata.system;
                   _this.createComponent(_this.system.menu)
                    _this.$forceUpdate();
                })
                .catch(function(err) {
                      console.log(err);
                    _this.$XModal.message({ message: "", status: 'error' })
                    //alert(JSON.stringify(err.response.data));
                });


            // this.system.menu = [
            // {
            //   pageFlag: false,
            //   id: "test",
            //   title: "title2",
            //   icon:"el-icon-menu",
            //   children: [
            //   {
            //     pageFlag: false,
            //     id: "test1",
            //     title: "title1",
            //     icon:"el-icon-menu",
            //     children: [
            //     {
            //       pageFlag: true,
            //       id: "hello1id",
            //       title: "hello1",
            //       name: "hello1",
            //       icon:"el-icon-document",
            //       component:"views/test/Hello1.vue"

            //     },
            //     {
            //       pageFlag: true,
            //       id: "hello2id",
            //       title: "hello2",
            //       name: "hello2",
            //       icon:"el-icon-document",
            //       component:"views/test/Hello2.vue"

            //     }

            //   ]
            //   }]
            // },
            // {
            //   pageFlag: false,
            //   id: "test3",
            //   title: "title4",
            //   icon:"el-icon-menu",
            //   children: []
            // }];
            // this.$ax.localdata.routerList = [
            // {
            //   pageFlag: false,
            //   id: "test",
            //   title: "title2",
            //   children: [
            //   {
            //     pageFlag: false,
            //     id: "test1",
            //     title: "title1",
            //     children: [
            //     {
            //       pageFlag: true,
            //       id: "hello1id",
            //       title: "hello1",
            //       name: "hello1",
            //       component:"views/test/Hello1.vue"

            //     }]
            //   }]
            // },
            // {
            //   pageFlag: false,
            //   id: "test3",
            //   title: "title4",
            //   children: []
            // }]

        }
        
    }
</script>
<style>
    .el-container {
        height: 100%;
    }

    .el-header {
        background-color: #B3C0D1;
        color: #333;
        line-height: 60px;
        position: relative;
    }

    .el-aside {
        color: #333;
    }

    .menu_btn {
        position: absolute;
        top: 0px;
        left: 0px;
        padding-left: 20px;
        font-size: 25px;
        padding-top: 20px;
    }
</style>