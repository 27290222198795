import Vue from 'vue'
import App from './App.vue'
import router from './router'
import  ax from  "@/js/ax.js"
import ElementUI from 'element-ui'
// import Antd from 'ant-design-vue';
// import { scrollY } from "@/js/scorllbar.js"; //滚动条
// Vue.prototype.escrollY = scrollY     //滚动条
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

// 全局引用
// Vue.use(Print);
// import '@/styles/VXETable_loading.scss'

Vue.use(VXETable)

Vue.prototype.$ax = ax;
Vue.prototype.$XModal = VXETable.modal;
Vue.use(ElementUI);
Vue.config.productionTip = false

// router.beforeEach((to,from,next)=>{
//   /* 路由发生变化修改页面title */
//   if(to.meta.title){
//     document.title = to.meta.title
//   }
// });

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


