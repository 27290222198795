import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/system/Login.vue";
import Main  from "@/system/Main.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/testtest',
    name: 'Home',
    component: Home,
    meta:{
      title:'耀安'
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta:{
      title:'耀安'
    }

  },
  {
    path: '/',
    name: 'main',
    component:Main,
    meta:{
      title:'耀安'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  params:"",
  flagecode:"view",
})

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {

// router.beforeEach((to,from,next)=>{
//   /* 路由发生变化修改页面title */
//   if(to.meta.title){
//     document.title = to.meta.title
//   }
// });
  if(to.meta.title){
    document.title = to.meta.title
  }
  if (to.path === '/login') {
    next();
  } else {
    let token = localStorage.getItem('Authorization');
    
    if (token === null || token === 'null' || token === '') {
      next('/login');
      // next() //测试
    } else {
      next();
    }
  }
});

export default router
