//  -yzm

//other
let __system = {
    __activeid: "",
    __menu: [{
        pageFlag: false,
        id: "quote",
        title: "实时报表管理",
        icon: "el-icon-menu",
        visible: true,
        children: [{
                pageFlag: false,
                id: "quotebase",
                title: "基础资料",
                icon: "el-icon-menu",
                visible: true,
                children: [
                    {
                        pageFlag: true,
                        id: "ax_z_inputcyclefgd",
                        title: "非固定周期",
                        name: "ax_z_inputcyclefgd",
                        icon: "el-icon-document",
                        component: "views/yaoan/AxZInputcyclefgdList.vue",
                        componentdetail: "views/yaoan/AxZInputcyclefgdDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_inputcyclegd",
                        title: "固定周期",
                        name: "ax_z_inputcyclegd",
                        icon: "el-icon-document",
                        component: "views/yaoan/AxZInputcyclegdList.vue",
                        componentdetail: "views/yaoan/AxZInputcyclegdDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_shurupjcn",
                        title: "平均产能",
                        name: "ax_z_shurupjcn",
                        icon: "el-icon-document",
                        component: "views/yaoan/AxZShurupjcnList.vue",
                        componentdetail: "views/yaoan/AxZShurupjcnDetail.vue",
                        visible: true
                    },
                ]
            },
            {
                pageFlag: false,
                id: "quotepz",
                title: "报表",
                icon: "el-icon-menu",
                visible: true,
                children: [
                    {
                        pageFlag: true,
                        id: "ax_z_newreport",
                        title: "订单实时完成表",
                        name: "ax_z_newreport",
                        icon: "el-icon-document",
                        component: "views/yaoan/AxZNewreportList.vue",
                        componentdetail: "views/yaoan/AxZNewreportDetail.vue",
                        visible: true
                    }
                ]
            },
        ],
    }, ],
    __mdilist: [],
    set activeid(val) {
        this.__activeid = val;
    },
    get activeid() {
        return this.__activeid;
    },
    set menu(val) {
        this.__menu = val;
    },
    get menu() {
        // console.log(this.__menu);
        return this.__menu;
    },
    set mdilist(val) {
        this.__mdilist = val;
    },
    get mdilist() {
        return this.__mdilist;
    }
}


let ld = (function(__system) {
    let _ld = {
        "system": __system,
        "type":"",
    }
    return _ld;
})(__system)
export default ld