<template>
  <el-scrollbar class="scrollbar-wrapper">
    <div style="height: 60px;">
      测试系统
    </div>
    <!-- 菜单 -->
    <el-menu :collapse="isCollapse" background-color="#304156" text-color="#fff" active-text-color="#409EFF">
      <sidebar-item v-for="(route,index) in system.menu" :key="index" :item="route" :system="system"/>
    </el-menu>
  </el-scrollbar>
</template>
<script>
  import SidebarItem from "./sidebarItem"
  import axios from 'axios';
  import ax from "@/js/ax.js"
  export default {
    props: {},
    extends:ax.control(),
    data() {
      return {
        isCollapse: false,
        editableTabsValue: undefined,
        editableTabsValue: "",
        routerList: []
      }
    },
    mounted() {
      this.routerList = [
        {
          pageFlag: false,
          id: "test",
          title: "title2",
          children: [
            {
              pageFlag: false,
              id: "test1",
              title: "title1",
              children: [
              {
              pageFlag: true,
              id: "hello1",
              title: "hello1",
              name:"hello1",
            }
              ]
            }
          ]
        }
      ]
    },
    methods:{
      test(){
        this.ax.localdata.routerList.push(
          {
            pageFlag: false,
          id: "test5",
          title: "title5",
          children: []
          }
        
        )
      },
    }
    ,
    components: {
      SidebarItem
    }
  }
</script>
<style lang="scss">

</style>