<!--<template>
    <div style="height: 100%;">
        <div style="heigth:300px;">
        </div>
        <el-form>
            <el-row>
                <el-col :span="8">登录界面
                 </el-col>
                <el-col :span="8">
                    
                    <el-form-item label="用户">
                        <el-row >
                    <el-col :span="18">
                <el-input v-model="login.name"></el-input>
                    </el-col>
                        </el-row>
            </el-form-item>
            <el-form-item label="密码">
                <el-row >
                    <el-col :span="18">
                    <el-input v-model="login.password" show-password></el-input>
                    </el-col>
                    <el-col :span="18">
                         <el-button @click="login1">登录</el-button>
                    </el-col>
                    </el-row>
            </el-form-item>
                </el-col>
                <el-col :span="4">
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
-->
<template>
  <div class="site-wrapper site-page--login">
    <div class="site-content__wrapper">
      <div class="site-content">
        <div class="login-main">
          <h3 class="login-title">登录</h3>
          <el-form   status-icon>
            <el-form-item >
              <el-input  placeholder="用户" v-model="login.name"></el-input>
            </el-form-item>
            <el-form-item >
              <el-input  v-model="login.password" show-password placeholder="密码"></el-input>
            </el-form-item>
         
            <el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-button  @click="login1" >登录</el-button>
                </el-col>
                <el-col :span="12">
                    <el-button  @click="login2" >查询登录</el-button>
                </el-col>
            </el-row>
              
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ax from "@/js/ax.js";
import * as math from "mathjs"
export default {
  data() {
    return {
      login: {
        name: "",
        password: null
      },
      exp:"",
   
    };
  },
  mounted(){
    if(1==0){
      // this.login.name="13128077250";

      // this.login.password="czxing9901";
      // this.login.password="";
    }  
   
  },
  methods: {
    login1() {
      let _this = this;
      if (this.login.name === "") {
          this.$XModal.message({ message: "账号或密码不能为空", status: 'error' })
         return;
      }
      if(this.$ax.model.type=="dev1"){
        localStorage.setItem("Authorization", this.ax.model.type);
        localStorage.setItem("userName", this.ax.model.type);
        localStorage.setItem("userFullName", this.ax.model.type);
       // localStorage.getItem("userName");
        _this.$router.push("/");
      }else{
        ax.base
        .post("/coreSystem/login/ufidaLogin", this.login)
        .then(function(rs) {
          localStorage.setItem("Authorization", rs.data.accessToken);
          localStorage.setItem("userName", _this.login.name);
          localStorage.setItem("userFullName", rs.data.userName);
          _this.$router.push("/");
        })
        .catch(function(err) {
          console.log(err);
          _this.$XModal.message({ message: "用户账号或密码不正确请重新输入", status: 'error' })
          //alert(JSON.stringify(err.response.data));
        });
      }
    },
    login2(){
        let _this = this;
      if (this.login.name === "") {
          this.$XModal.message({ message: "账号或密码不能为空", status: 'error' })
         return;
      }
      if(this.$ax.model.type=="dev1"){
        localStorage.setItem("Authorization", this.ax.model.type);
        localStorage.setItem("userName", this.ax.model.type);
        localStorage.setItem("userFullName", this.ax.model.type);
       // localStorage.getItem("userName");
        _this.$router.push("/");
      }else{
        ax.base
        .post("/coreSystem/login/queryLogin", this.login)
        .then(function(rs) {
        
          localStorage.setItem("Authorization", rs.data.accessToken);
     
          localStorage.setItem("userName", _this.login.name);
  
          localStorage.setItem("userFullName", rs.data.userName);
      
          _this.$router.push("/");
        })
        .catch(function(err) {
          console.log(err);
          _this.$XModal.message({ message: "用户账号或密码不正确请重新输入", status: 'error' })
          //alert(JSON.stringify(err.response.data));
        });
      }

    }
  }
};
</script>


<style lang="scss">
  .site-wrapper.site-page--login {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    .site-content {
      min-height: 100%;
      padding: 30px 30px 30px 30px;
    }
    .brand-info {
      margin: 220px 100px 0 90px;
      color: #fff;
    }
    .brand-info__text {
      margin:  0 0 22px 0;
      font-size: 48px;
      font-weight: 400;
      text-transform : uppercase;
    }
    .brand-info__intro {
      margin: 10px 0;
      font-size: 16px;
      line-height: 1.58;
      opacity: .6;
    }
    .login-main {
      position: absolute;
      top: 0;
      right: 35%;
      padding: 150px 60px 180px 300px;
      width: 470px;
      min-height: 100%;
      background-color: #fff;
    }
    .login-title {
      font-size: 16px;
    }
    .login-captcha {
      overflow: hidden;
      > img {
        width: 100%;
        cursor: pointer;
      }
    }
    .login-btn-submit {
      width: 20%;
      
    }
  }
</style>
