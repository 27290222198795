<template>
<el-submenu v-if="!item.pageFlag" :index="String(item.id)" style="text-align:left;"  v-show="item.visible" >
  <template slot="title">
    <i :class="item.icon"></i>
    <span slot="title">{{item.title}}</span>
  </template>
  <sidebar-item v-for="item1 in item.children" :key="item1.id" :item="item1" :system="system"/>
</el-submenu>
<el-menu-item v-else :index="String(item.id)" @click="pushItem(item)" v-show="item.visible" >
    <i :class="item.icon"></i>
    <span slot="title">{{item.title}}</span>
</el-menu-item>
</template>
<script>
  import ax from "@/js/ax.js"
export default {
  name: 'sidebarItem',
  extends:ax.control(),
  components:{
  },
  data() {
   return{

   }
  },
  mounted() {
    // this.item=[];
  },
  methods: {
   pushItem(item){
    // console.log(item);
    let tables = {
      id:item.id,
      title:item.title,
      name:item.name,
      content: item.name,
      icon:item.icon,
      "componentdetail":item.componentdetail
     }
     if(!this.$ax.arr.indexof(this.system.mdilist,tables)){
        this.system.mdilist.push(tables)
        this.system.activeid = item.id
     }else{
      this.system.activeid = item.id
     } 
   } 
  }
  
}
</script>
